.App {
  text-align: center;
}

body {
  height: 100vh;
  width: 100%;
}

#root {
  height: 100%;
  
  .App {
    height: 100%;
    width: 100%;
    display: flex;
  }
}
